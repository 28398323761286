import React, { useContext } from "react";
import Header from "./Header";
import Progressbar from "./Progressbar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { Outlet } from "react-router-dom";
import { ModeContext } from "../contexts/ModeContext";

const RootLayout = () => {
  const { mode } = useContext(ModeContext);
  return (
    <div id={mode} className="root-layout">
      <ScrollToTop />
      <Progressbar />
      <Header />
      <main id="main">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default RootLayout;
