import React, { createContext, useState } from "react";

export const ModeContext = createContext();

export const ModeContextProvider = (props) => {
  const [mode, setMode] = useState("daymode");
  const handleMode = () => {
    if (mode === "daymode") {
      setMode("darkmode");
    } else {
      setMode("daymode");
    }
  };
  return (
    <ModeContext.Provider
      value={{
        mode,
        setMode,
        handleMode,
      }}
    >
      {props.children}
    </ModeContext.Provider>
  );
};
