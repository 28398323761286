import React from "react";
import projects from "../assets/db.json";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const projectArray = projects.projects;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="portfolio-grid">
        {projectArray.map((project) => (
          <Link
            to={`archives/${project.name}`}
            key={project.id}
            state={{ id: project.id }}
            className="project-link"
          >
            <div className="project-tile">
              <img
                src={require(`../assets/images/${project.name}/${project.name}-cover.jpg`)}
                alt={project.name}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
