import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { ModeContext } from "../contexts/ModeContext";
import Portfolio from "../components/Portfolio";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { mode } = useContext(ModeContext);
  const { t } = useTranslation();

  // const BlockContainer = (props) => {
  //   const navigate = useNavigate();
  //   const handleClick = () => {
  //     if (props.link.startsWith("http")) {
  //       window.open(props.link, "_blank");
  //     } else {
  //       navigate(props.link);
  //     }
  //   };

  //   return (
  //     <div onClick={handleClick} className="category block">
  //       <img src={props.img} alt={props.title}></img>
  //       <p className="pretitle">{props.pretitle}</p>
  //       <h3>{props.title}</h3>
  //       <p>{props.shortdef}</p>
  //     </div>
  //   );
  // };

  return (
    <>
      <section>
        <p className="pretitle">Portfolio</p>
        <h1>{t("h1Home")}</h1>
        <div className="introduction-container">
          <img
            className="img-intro"
            src={require(mode === "daymode"
              ? "../assets/images/daymode.png"
              : "../assets/images/darkmode.png")}
            alt=""
          />
          <div className="introduction-text">
            <ReactMarkdown>{t("introP1")}</ReactMarkdown>
            <ReactMarkdown>{t("introP2")}</ReactMarkdown>
            <Link to="/approach"> {t("linkApproach")}</Link>
            <div id="showcase" />
          </div>

          <h2 style={{ marginBottom: "0" }} className="highlightTitle">
            {t("highlightTitle")}
          </h2>

          <div className="block-container-container">
            <p style={{ margin: "0" }}>{t("velmaParagraph")}</p>
          </div>
          <a className="velma-button" href="https://www.velma.studio">
            {t("velmaButton")}
          </a>
        </div>
        <h2>Archives</h2>
      </section>
      <Portfolio />
    </>
  );
};

export default Home;
