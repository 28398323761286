import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import projects from "../assets/db.json";

const Project = () => {
  const { name } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const currentUrl = location.pathname;
  const isLab = currentUrl.includes("lab");
  const projectArray = isLab ? projects.lab : projects.projects;
  const project = projectArray.find((p) => p.name === name);
  const [images, setImages] = useState([]);
  const {
    title,
    website,
    year,
    type,
    url,
    tech1,
    repo1,
    tech2,
    repo2,
    creditLine1,
    creditLine2,
    creditLine3,
  } = project;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imagePromises = Array.from({ length: 18 }).map(
          async (_, index) => {
            const imageIndex = 17 - index;
            const imageModule = await import(
              `../assets/images/cabaretlitteraire/grid/cabaretlitteraire_${imageIndex
                .toString()
                .padStart(2, "0")}.jpg`
            );
            return imageModule.default;
          }
        );

        const loadedImages = await Promise.all(imagePromises);
        setImages(loadedImages);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    fetchImages();
  }, []);

  const http = `https://${url}`;
  const renderImage = website && (
    <a
      className="project-image-link"
      target="_blank"
      rel="noopener noreferrer"
      href={http}
    >
      <img
        className="project-mockup"
        src={require(`../assets/images/${name}/${name}-responsive.png`)}
        alt={title}
      />
    </a>
  );

  return (
    <div className="project-details">
      <section>
        <p className="pretitle">
          {year} · {type}
        </p>
        <h1>{title}</h1>

        <div className="project-body">
          {website ? (
            <>
              {renderImage}
              <div>
                <ReactMarkdown>{t(`${name}Client`)}</ReactMarkdown>
                <ReactMarkdown>{t(`${name}Gig`)}</ReactMarkdown>
              </div>
            </>
          ) : (
            <>
              <div>yolo</div>
              <div>
                <ReactMarkdown>{t(`${name}Client`)}</ReactMarkdown>
                <ReactMarkdown>{t(`${name}Gig`)}</ReactMarkdown>
              </div>
            </>
          )}
        </div>

        {title === "Le Cabaret Littéraire" && (
          <div className="print-project-img-grid">
            {images.map((imageSrc, index) => (
              <img
                key={index}
                className="print-project-img"
                alt="affiche Cabaret Littéraire"
                src={imageSrc}
              />
            ))}
          </div>
        )}

        <div className="credit-container">
          {website && (
            <div className="link-url">
              <span>Website: </span>
              <a target="_blank" rel="noopener noreferrer" href={http}>
                {url}
              </a>
            </div>
          )}

          <p className="credit-lines">
            {tech2 ? (
              <p>
                Version beta: {tech1} – <a href={repo1}>GitHub Repo</a>
                <br />
                Version 1.0: {tech2} – <a href={repo2}>GitHub Repo</a>
              </p>
            ) : tech1 ? (
              <>
                <span>Technologies: {tech1}</span>
                {repo1 && (
                  <>
                     – <a href={repo1}>GitHub Repo</a>
                    <br />
                  </>
                )}
              </>
            ) : null}

            <span>{creditLine1}</span>
            <br />
            <span>{creditLine2}</span>
            <br />
            <span>{creditLine3}</span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Project;
