import React from "react";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./components/Home";
import Approach from "./components/Approach";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Project from "./components/Project";
import NotFound from "./components/NotFound";
import RootLayout from "./components/RootLayout";
import { ModeContextProvider } from "./contexts/ModeContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./lang/i18next";

//
const routes = createRoutesFromElements(
  <Route path="/" element={<RootLayout />}>
    <Route index element={<Home />} />
    <Route path="approach" element={<Approach />} />
    <Route path="about" element={<About />} />
    <Route path="archives" element={<Portfolio />} />
    <Route path="lab" element={<Portfolio />} />
    <Route path="archives/:name" element={<Project />} />
    <Route path="lab/:name" element={<Project />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

const router = createBrowserRouter(routes);

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ModeContextProvider>
        <RouterProvider router={router} />
      </ModeContextProvider>
    </I18nextProvider>
  );
}
export default App;
