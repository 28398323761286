import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section>
      <h1>Error 404</h1>
      <p>
        The page that you are looking for doesn’t exist. Go Back to{" "}
        <Link to="/">Homepage</Link>.
      </p>
    </section>
  );
};

export default NotFound;
