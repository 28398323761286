import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { ModeContext } from "../contexts/ModeContext";
import { HashLink as Link } from "react-router-hash-link";
import i18n from "../lang/i18next";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { mode, handleMode } = useContext(ModeContext);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleNavLink = () => {
    setIsOpen(false);
  };

  const handleLang = (lg) => {
    switch (lg) {
      case "fr":
        i18n.changeLanguage("fr");
        break;
      case "en":
        i18n.changeLanguage("en");
        break;
      case "de":
        i18n.changeLanguage("de");
        break;
      default:
        i18n.changeLanguage("en");
        break;
    }
    document.documentElement.lang = lg;
    const newDescription = t("metaDescription");
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );

    const newTitle = t("metaTitle");
    const metaTitleTag = document.querySelector("title");

    metaDescriptionTag.setAttribute("content", newDescription);
    metaTitleTag.setAttribute("content", newTitle);
  };

  return (
    <>
      {/* <div className="header-top-banner">
        <span>
          <a href="mailto:hello@roxanne-borloz.net">hello@roxanne-borloz.net</a>
          {"  ·  "}
          <a href="tel:004915231680275">+49(0)1523/1680275</a>
        </span>
      </div> */}
      <header>
        <div className="header-container">
          <a className="skip-to-content-link" href="#main">
            {t("Skip")}
          </a>

          <button className="burger-menu" onClick={() => openMenu()}>
            <span id="nav-icon" className={!isOpen ? "close" : "open"}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span className="menu-label screen-reader-text">menu</span>
          </button>

          <nav className={isOpen ? "menu vertical" : "menu horizontal"}>
            <Link onClick={handleNavLink} to="/#showcase">
              Portfolio
            </Link>
            <NavLink onClick={handleNavLink} to="/approach">
              {t("Approach")}
            </NavLink>
            <NavLink onClick={handleNavLink} to="/about">
              {t("About")}
            </NavLink>
          </nav>

          <Link id="logo-header" to="/">
            <strong>Roxanne Borloz</strong>
          </Link>
          <div className="secondary">
            <button className="mode-button" onClick={handleMode}>
              {mode === "daymode" ? (
                <>
                  <svg
                    className="daymode-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
                  </svg>

                  <span className="daymode-text"> Lightmode</span>
                </>
              ) : (
                <>
                  <svg
                    className="darkmode-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z" />
                  </svg>

                  <span className="darkmode-text"> Darkmode</span>
                </>
              )}
            </button>

            <div className="dropdown">
              <button
                className="dropbtn mode-button"
                onKeyDown={() => {
                  const nextLang =
                    i18n.language === "de"
                      ? "en"
                      : i18n.language === "en"
                      ? "fr"
                      : "de";
                  i18n.changeLanguage(nextLang);
                }}
              >
                <svg
                  className={
                    mode === "daymode" ? "daymode-icon" : "darkmode-icon"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 510 512"
                >
                  <path d="M266.3 48.3L232.5 73.6c-5.4 4-8.5 10.4-8.5 17.1v9.1c0 6.8 5.5 12.3 12.3 12.3c2.4 0 4.8-.7 6.8-2.1l41.8-27.9c2-1.3 4.4-2.1 6.8-2.1h1c6.2 0 11.3 5.1 11.3 11.3c0 3-1.2 5.9-3.3 8l-19.9 19.9c-5.8 5.8-12.9 10.2-20.7 12.8l-26.5 8.8c-5.8 1.9-9.6 7.3-9.6 13.4c0 3.7-1.5 7.3-4.1 10l-17.9 17.9c-6.4 6.4-9.9 15-9.9 24v4.3c0 16.4 13.6 29.7 29.9 29.7c11 0 21.2-6.2 26.1-16l4-8.1c2.4-4.8 7.4-7.9 12.8-7.9c4.5 0 8.7 2.1 11.4 5.7l16.3 21.7c2.1 2.9 5.5 4.5 9.1 4.5c8.4 0 13.9-8.9 10.1-16.4l-1.1-2.3c-3.5-7 0-15.5 7.5-18l21.2-7.1c7.6-2.5 12.7-9.6 12.7-17.6c0-10.3 8.3-18.6 18.6-18.6H400c8.8 0 16 7.2 16 16s-7.2 16-16 16H379.3c-7.2 0-14.2 2.9-19.3 8l-4.7 4.7c-2.1 2.1-3.3 5-3.3 8c0 6.2 5.1 11.3 11.3 11.3h11.3c6 0 11.8 2.4 16 6.6l6.5 6.5c1.8 1.8 2.8 4.3 2.8 6.8s-1 5-2.8 6.8l-7.5 7.5C386 262 384 266.9 384 272s2 10 5.7 13.7L408 304c10.2 10.2 24.1 16 38.6 16H454c6.5-20.2 10-41.7 10-64c0-111.4-87.6-202.4-197.7-207.7zm172 307.9c-3.7-2.6-8.2-4.1-13-4.1c-6 0-11.8-2.4-16-6.6L396 332c-7.7-7.7-18-12-28.9-12c-9.7 0-19.2-3.5-26.6-9.8L314 287.4c-11.6-9.9-26.4-15.4-41.7-15.4H251.4c-12.6 0-25 3.7-35.5 10.7L188.5 301c-17.8 11.9-28.5 31.9-28.5 53.3v3.2c0 17 6.7 33.3 18.7 45.3l16 16c8.5 8.5 20 13.3 32 13.3H248c13.3 0 24 10.7 24 24c0 2.5 .4 5 1.1 7.3c71.3-5.8 132.5-47.6 165.2-107.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM187.3 100.7c-6.2-6.2-16.4-6.2-22.6 0l-32 32c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l32-32c6.2-6.2 6.2-16.4 0-22.6z" />
                </svg>
                <span className="lang-text"> {t("Lang")}</span>
              </button>
              <div className="dropdown-content">
                <button className="lang" onClick={() => handleLang("fr")}>
                  Français
                </button>
                <button className="lang" onClick={() => handleLang("en")}>
                  English
                </button>
                <button className="lang" onClick={() => handleLang("de")}>
                  Deutsch
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
