import React from "react";

const Progressbar = () => {
  {
    window.onscroll = function() {
      progressBar();
    };

    function progressBar() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      document.getElementById("progressBar").style.width = scrolled + "%";
    }
  }
  return (
    <div className="progress-container">
      <div className="progress-bar" id="progressBar" />
    </div>
  );
};
export default Progressbar;
