import React from "react";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { SanityLogo } from "@sanity/logos";
const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <section>
        <p className="pretitle">{t("About")}</p>
        <h1>{t("h1About")}</h1>

        <div className="subtitle">
          <ReactMarkdown>{t("subtitleAbout")}</ReactMarkdown>
        </div>
        <figure>
          <img
            id="profile"
            src={require("../assets/images/profile.jpg")}
            alt="Roxanne Borloz"
          />
        </figure>

        <ReactMarkdown>{t("AboutP1")}</ReactMarkdown>
        <div className="dev-container">
          <p className="pretitle">Best of dev </p>
          <ReactMarkdown>{t("AboutDev1")}</ReactMarkdown>
          {/* <ReactMarkdown>{t("AboutDev2")}</ReactMarkdown> */}
        </div>

        <ReactMarkdown>{t("AboutP2")}</ReactMarkdown>
        <ReactMarkdown>{t("AboutP3")}</ReactMarkdown>
        <ReactMarkdown>{t("AboutP4")}</ReactMarkdown>
        <ReactMarkdown>{t("AboutP5")}</ReactMarkdown>
        <p>
          <a
            style={{ borderBottom: "none" }}
            href="mailto:hello@roxanne-borloz.net"
          >
            <button>{t("contactLink")}</button>
            <span className="screen-reader-text">({t("openEmail")})</span>
          </a>
        </p>

        <div className="dev-logo-container">
          <i alt="html5" className="devicon-html5-plain-wordmark tooltip">
            <span className="tooltiptext">HTML 5</span>
          </i>
          <i alt="css" className="devicon-css3-plain-wordmark tooltip">
            <span className="tooltiptext">CSS 3</span>
          </i>
          <i
            alt="wordpress"
            className="devicon-wordpress-plain-wordmark tooltip"
          >
            <span className="tooltiptext">WordPress</span>
          </i>
          <i alt="sass" className="devicon-sass-original tooltip">
            <span className="tooltiptext">Sass</span>
          </i>
          <i
            alt="tailwind"
            className="devicon-tailwindcss-original-wordmark tooltip"
          >
            <span className="tooltiptext">Tailwind</span>
          </i>
          <i alt="git" className="devicon-git-plain-wordmark tooltip">
            <span className="tooltiptext">Git</span>
          </i>
          <i alt="github" className="devicon-github-original-wordmark tooltip">
            <span className="tooltiptext">GitHub</span>
          </i>
          <i alt="javascript" className="devicon-javascript-plain tooltip">
            <span className="tooltiptext">Javascript</span>
          </i>
          <i alt="typescript" className="devicon-typescript-plain tooltip">
            <span className="tooltiptext">Typescript</span>
          </i>
          <i alt="react" className="devicon-react-plain-wordmark tooltip">
            <span className="tooltiptext">React</span>
          </i>
          <i alt="next" className="devicon-nextjs-plain-wordmark tooltip">
            <span className="tooltiptext">Next.js</span>
          </i>
          <i alt="firebase" className="devicon-firebase-plain-wordmark tooltip">
            <span className="tooltiptext">Firebase</span>
          </i>
          <i alt="mongo db" className="devicon-mongodb-plain-wordmark tooltip">
            <span className="tooltiptext">MongoDB</span>
          </i>
          <i alt="graph ql" className="devicon-graphql-plain-wordmark tooltip">
            <span className="tooltiptext">GraphQL</span>
          </i>
          <i alt="node js" className="devicon-nodejs-plain-wordmark tooltip">
            <span className="tooltiptext">Node.js</span>
          </i>
          <i
            alt="express"
            className="devicon-express-original-wordmark tooltip"
          >
            <span className="tooltiptext">Express</span>
          </i>
          <i alt="storybook" className="devicon-storybook-plain tooltip">
            <span className="tooltiptext">Storybook</span>
          </i>
          <div className="tooltip">
            <SanityLogo alt="sanity" style={{ fontSize: 30 }}>
              <span className="tooltiptext">Sanity</span>
            </SanityLogo>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
