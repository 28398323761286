import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-container">
        <p>Roxanne Borloz ({t("pronouns")})</p>
        <div className="contact-links">
          <a className="email" href="mailto:hello@roxanne-borloz.net">
            hello@roxanne-borloz.net{" "}
            <span className="screen-reader-text">(open an email)</span>
          </a>

          <p className="social-media-container">
            <a
              className="social-media"
              href="https://github.com/ROXBOZ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                alt="github"
                className="github"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 500 500"
                style={{ enableBackground: "new 0 0 500 500" }}
                xmlSpace="preserve"
              >
                <circle cx={250} cy={250} r={250} />
                <path
                  d="M192.3,353.8c0,1.4-1.6,2.5-3.7,2.5c-2.3,0.2-3.9-0.9-3.9-2.5c0-1.4,1.6-2.5,3.7-2.5
	C190.5,351,192.3,352.2,192.3,353.8z M170.4,350.6c-0.5,1.4,0.9,3,3,3.4c1.8,0.7,3.9,0,4.4-1.4s-0.9-3-3-3.7
	C172.9,348.5,170.9,349.2,170.4,350.6L170.4,350.6z M201.5,349.4c-2,0.5-3.4,1.8-3.2,3.4c0.2,1.4,2,2.3,4.1,1.8
	c2-0.5,3.4-1.8,3.2-3.2C205.4,350.1,203.5,349.2,201.5,349.4z M247.8,80c-97.5,0-172.1,74-172.1,171.5c0,78,49.1,144.7,119.2,168.2
	c9,1.6,12.2-3.9,12.2-8.5c0-4.4-0.2-28.4-0.2-43.2c0,0-49.2,10.5-59.5-21c0,0-8-20.5-19.5-25.7c0,0-16.1-11,1.1-10.8
	c0,0,17.5,1.4,27.1,18.1c15.4,27.1,41.2,19.3,51.3,14.7c1.6-11.2,6.2-19.1,11.2-23.7c-39.3-4.4-79-10.1-79-77.7
	c0-19.3,5.3-29,16.6-41.4c-1.8-4.6-7.8-23.4,1.8-47.7c14.7-4.6,48.5,19,48.5,19c14.1-3.9,29.2-6,44.2-6c15,0,30.1,2,44.2,6
	c0,0,33.8-23.6,48.5-19c9.6,24.4,3.7,43.2,1.8,47.7c11.2,12.4,18.1,22.1,18.1,41.4c0,67.8-41.4,73.3-80.7,77.7
	c6.5,5.6,12,16.1,12,32.6c0,23.7-0.2,53-0.2,58.8c0,4.6,3.2,10.1,12.2,8.5c70.3-23.3,118-90.1,118-168C424.4,154,345.3,80,247.8,80z
	 M144,322.5c-0.9,0.7-0.7,2.3,0.5,3.7c1.1,1.1,2.7,1.6,3.7,0.7c0.9-0.7,0.7-2.3-0.5-3.7C146.5,322.1,144.9,321.6,144,322.5z
	 M136.4,316.8c-0.5,0.9,0.2,2,1.6,2.7c1.1,0.7,2.5,0.5,3-0.5c0.5-0.9-0.2-2-1.6-2.7C138,315.9,136.9,316.1,136.4,316.8z
	 M159.2,341.8c-1.1,0.9-0.7,3,0.9,4.4c1.6,1.6,3.7,1.8,4.6,0.7c0.9-0.9,0.5-3-0.9-4.4C162.2,340.9,160.1,340.7,159.2,341.8z
	 M151.2,331.5c-1.1,0.7-1.1,2.5,0,4.1s3,2.3,3.9,1.6c1.1-0.9,1.1-2.7,0-4.4C154.1,331.3,152.3,330.6,151.2,331.5L151.2,331.5z"
                />
              </svg>
              GitHub
              <span className="screen-reader-text">
                (open link in a new tab)
              </span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
