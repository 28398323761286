import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";

const Approach = () => {
  const { t } = useTranslation();

  const approachItems = [
    {
      title: "Maintainability",
      p1: "MaintainabilityP1",
      p2: "MaintainabilityP2",
      linkDemo: true,
    },
    { title: "Scalability", p1: "ScalabilityP1", p2: "ScalabilityP2" },
    { title: "Design inclusif", p1: "Beau", p2: "Politique" },
    { title: "Multilingue", p1: "Facile", p2: "Typographie", linkDemo: true },
    {
      title: "Maintenance et post-lancement",
      p1: "Lorem ipsum",
      p2: "Lorem ipsum",
    },
    {
      title: "Accessibility",
      p1: "AccessibilityP1",
      p2: "AccessibilityP2",
    },
  ];

  return (
    <section>
      <p className="pretitle">{t("Approach")}</p>
      <h1>{t("h1Approach")}</h1>
      <div className="subtitle">
        <ReactMarkdown>{t("subtitleApproach")}</ReactMarkdown>
      </div>
      {approachItems.map((item, index) => (
        <div key={index} className="approach-item">
          <div>
            <h2 className="h3">
              <span className="colored">#{index + 1}</span> {t(item.title)}
            </h2>
            {/* {item.linkDemo === true && <a href="">Demo</a>}
            {item.linkA11y === true && <a href="https://sanity.io">A11Y</a>} */}
          </div>
          <div>
            <ReactMarkdown>{t(item.p1)}</ReactMarkdown>
            <ReactMarkdown>{t(item.p2)}</ReactMarkdown>
          </div>
        </div>
      ))}
      <a
        style={{ borderBottom: "none", textAlign: "right" }}
        href="mailto:hello@roxanne-borloz.net"
      >
        <button>{t("contactLink")}</button>
        <span className="screen-reader-text">({t("openEmail")})</span>
      </a>
    </section>
  );
};

export default Approach;
